#contador_tiempo {
    position: absolute;
    z-index: 1050;
    @include respond-to ('small') {
        top: 8rem;
    }
    @include respond-to ('medium') {
        top: 9rem;
    }
    @include respond-to ('large') {
        top: 10rem;
    }
    &.arriba {
        top: 2rem;
    }
    &.promo-det {
        position: relative;
        top: 0;
        border: 1px solid $primary-blue;
        padding: 1rem 0 0 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 1040;
    }
    span {
        color: $primary-blue;
        &.color-verde {
            color: $primary-green;
        }
        &.color-azul {
            color: $primary-blue;
        }
        &.color-naranja {
            color: $primary-orange;
        }
        &.color-blanco {
            color: $primary-grey-light-light;
        }
        @include respond-to ('small') {
            font-size: 2.5rem;
        }
        @include respond-to ('medium') {
            font-size: 3rem;
        }
        @include respond-to ('large') {
            font-size: 3rem;
        }
        &.texto-contador {
            &.color-verde {
                color: $primary-green;
            }
            &.color-azul {
                color: $primary-blue;
            }
            &.color-naranja {
                color: $primary-orange;
            }
            &.color-blanco {
                color: $primary-grey-light-light;
            }
            display: block;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: -1rem;
            @include respond-to ('small') {
                font-size: 1.2rem;
            }
            @include respond-to ('medium') {
                font-size: 1.4rem;
            }
            @include respond-to ('large') {
                font-size: 1.4rem;
            }
            &.texto-det {
                color: $primary-blue;
            }
        }
    }
}
