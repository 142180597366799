button, a.btn {
    color: $primary-orange;
    border: none;
    @include border-radius($border-radius);
    //text-transform: uppercase;
    height: $height-cta;
    &.btn{
        border: none;
    }

    &.btn-primary{
        @include border-radius(2rem);
        @include gradient($primary-green, $primary-green-dark);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        width: 60%;
        display: block;
        margin-right: auto;
        margin-left: auto;
        color: $primary-grey-light;
        font-family: $font-type-coolvetica;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $primary-green;
            outline: none;
        }
    }

    &.btn-blanco {
        color: $primary-grey-light;
    }

    &.btn-azul {
        color: $primary-blue;
    }

    &.btn-anterior {
        @include border-radius(2rem);
        @include gradient($primary-green, $primary-green-dark);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        width: 30%;
        display: inline-block;
        //margin-right: auto;
        //margin-left: auto;
        color: $primary-grey-light;
        font-family: $font-type-coolvetica;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $primary-green;
            outline: none;
        }
    }
    &.btn-siguiente {
        @include border-radius(2rem);
        @include gradient($primary-green, $primary-green-dark);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        width: 30%;
        display: inline-block;
        //margin-right: auto;
        //margin-left: auto;
        color: $primary-grey-light;
        margin-left: 20%;
        font-family: $font-type-coolvetica;
        float: right;
        margin-right: 1rem;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $primary-green;
            outline: none;
        }
    }
    &.btn-izquierdo {
        @include border-radius(2rem);
        @include gradient($primary-green, $primary-green-dark);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        display: inline-block;
        color: $primary-grey-light;
        font-family: $font-type-coolvetica;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $primary-green;
            outline: none;
        }
    }
    &.btn-derecho {
        @include border-radius(2rem);
        @include gradient($primary-green, $primary-green-dark);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        display: inline-block;
        color: $primary-grey-light;
        vertical-align: top !important;
        position: relative;
        float: right;
        font-family: $font-type-coolvetica;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $primary-green;
            outline: none;
        }
    }
    &.btn-primary-verde {
        @include border-radius(2rem);
        @include gradient($primary-green, $primary-green-dark);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        width: 80%;
        display: block;
        margin-right: auto;
        margin-left: auto;
        color: $primary-grey-light;
        font-family: $font-type-coolvetica;
        margin-top: 1rem;
        height: 35px;
        padding-top: 0.6rem;
        @include respond-to('small'){
            font-size: .8rem;
        }
        @include respond-to('medium'){
            font-size: .9rem;
        }
        @include respond-to('large'){
            font-size: 1rem;
        }
    }
    &.boton-pago {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        left: 25%;
        @include respond-to('small'){
            top: 6.2rem;
        }
        @include respond-to('medium'){
            top: 7rem;
        }
        @include respond-to('large'){
            top: 7.8rem;
        }
    }
    &.btn-outline-primary {
        border: .1rem solid $primary-green;
        @include border-radius(1rem);
        @include box-shadow(0.3rem, 0.3rem, 1rem, $header-shadow);
        font-weight: bold;
        color: $primary-green;
        font-family: $font-type-coolvetica;
        display: table;
        margin-left: auto;
        margin-right: auto;
        padding-top: 0.3rem;
        height: auto;
        @include respond-to('small'){
            font-size: .8rem;
        }
        @include respond-to('medium'){
            font-size: .9rem;
        }
        @include respond-to('large'){
            font-size: 1rem;
        }
    }
    &.btn-primary-azul {
        @include gradient($secondary-blue, $primary-blue);
        font-size: 1.2rem;
    }
}

a.btn {
    padding-top: 0.8rem;
}

.boton-anterior {
    svg {
        color: $primary-blue;
        font-size: 1.6rem;
    }
}
