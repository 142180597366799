
// -----------------------------------------------------------------------------
// This file imports all components.
// -----------------------------------------------------------------------------
@import
    'header',
    'slider',
    'footer'
    ;

.wrapper {
    min-height: 100vh;
}
