.dashboard-autogestion {
    h5 {
        margin-top: 0;
    }
    .card {
        @include border-radius(2rem);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        border: none;
        .card-header {
            @include border-radius-partial(2rem, 2rem, 0, 0);
            text-align: center;
            font-size: 1.4rem;
            color: $light-base;
            background: $primary-green;
            border-bottom: none;
            font-weight: bold;
        }
        .card-body {
            background: $light-base;
            @include border-radius-partial(0, 0 , 2rem, 2rem);
            .card-title {
                font-size: 1.8rem;
                text-align: center;
                font-weight: bold;
                color: $primary-blue;
            }
            .card-text {
                color: $primary-blue;
            }
        }
        &.card-green {
            .card-body {
                @include border-radius-partial(2rem, 2rem, 2rem, 2rem);
                background: $primary-green;
                .card-title {
                    font-size: 1.8rem;
                    text-align: center;
                    font-weight: bold;
                    color: $white;
                }
                .card-text {
                    color: $white;
                }
            }
        }
        &.card-orange {
            .card-body {
                @include border-radius-partial(2rem, 2rem, 2rem, 2rem);
                background: $primary-orange;
                .card-title {
                    font-size: 1.8rem;
                    text-align: center;
                    font-weight: bold;
                    color: $white;
                }
                .card-text {
                    color: $white;
                }
            }
        }
    }
}