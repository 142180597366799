img {
    &.fondo1 {
        width: 100%;
        z-index: 1010;
        position: relative;
        @include respond-to('small'){
            margin-top: -20rem;
        }
        @include respond-to('medium'){
            margin-top: -24rem;
        }
        @include respond-to('large'){
            margin-top: -26rem;
        }
    }
    &.fondo2 {
        width: 100%;
        z-index: 1010;
        margin-top: -1rem;
        position: relative;
    }
    &.fondo-beneficios {
        width: 100%;
        position: relative;
        z-index: 1010;
    }
    &.fondo4 {
        width: 100%;
        z-index: 1010;
        position: relative;
    }
}
.imagen-tienda {
    img {
        width: 100%;
    }
}
.form-solicitud-home {
    position: relative;
    z-index: 1030;
    //@include box-shadow(0rem, 0.5rem, 1.5rem, $header-shadow);
    #form_tarjeta {
        margin: 2rem;
        background-color: $primary-grey-dark;
        border-radius: 3rem;
        position: relative;
        top: -3rem;
        padding: .1rem .1rem .1rem 0;
        div.form-group {
            margin: 0;
            padding: 0;
        }
        input {
            border-radius: 2rem;
            margin-left: 0.6rem;
        }
        button {
            @include border-radius(2rem);
            //padding-right: 2rem;
            text-align: center;
            font-weight: bold;
            @include gradient($primary-green, $primary-green-dark);
            padding: 0rem 0.5rem 0rem 0rem;
            -webkit-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3),0 0 3rem rgba(0, 0, 0, 0.1) inset;
            -moz-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 0 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
            //@include box-shadow-2(1rem, 0rem, 1.5rem, $box-shadow, false);
            width: 100%;
            height: 4rem;
        }
    }
}
.cajas-pagos {
    z-index: 1020;
    position: absolute;
    @include respond-to('small'){
        margin-top: -3rem;
    }
    @include respond-to('medium'){
        margin-top: -3rem;
    }
    @include respond-to('large'){
        margin-top: -1rem;
    }
    .bloques-home {
        .card-home {
            @include border-radius-partial(2rem, 2rem, 2rem, 2rem);
            img {
                @include border-radius-partial(0, 0, 2rem, 2rem);
                width: 100%;
            }
            .card-header {
                @include border-radius-partial(2rem, 2rem, 0, 0);
                line-height: 1;
                text-align: center;
                color: $primary-blue;
                font-weight: bold;
            }
            .card-body {
                padding: 0;
                @include border-radius-partial(0, 0, 2rem, 2rem);
            }
        }

        a {
            width: 70%;
            margin-right: auto;
            margin-left: auto;
            margin-top: -.6rem;
            z-index: 1030;
            position: relative;
            font-size: 1rem;
            padding-top: 0.8rem;
            @include gradient($primary-green, $primary-green-dark);
        }
    }
    &.pagos-online {
        position: relative;
        margin-top: 3rem;
        margin-bottom: 5rem;
        .card-home {
            .card-header {
                background-color: $primary-green;
                color: $white;
            }
            .card-body {
                background-color: $primary-green;
            }
        }
    }
}
.textos-beneficios {
    font-family: $font-type-coolvetica;
    background-color: $primary-blue;
    //height: 10rem;
    //-webkit-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3),0 0 3rem rgba(0, 0, 0, 0.1) inset;
    //-moz-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
    //box-shadow:0 0 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
    display: flex;
    justify-content: center;
    align-items: center;
    //@include border-radius(1rem);
    flex-wrap: wrap;
    z-index: 1020;
    p {
        color: $primary-grey-light;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 0;
    }
    a {
        @include border-radius(2rem);
        width: 50%;
        text-align: center;
        @include gradient($primary-green, $primary-green-dark);
        -webkit-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3),0 0 3rem rgba(0, 0, 0, 0.1) inset;
        -moz-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
        box-shadow:0 0 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
    }
    .boton-texto {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            padding-top: .6rem;
            z-index: 1040;
            font-size: 1.2rem;
            border: 1px solid $primary-grey;
            @include gradient($primary-green, $primary-green-dark);
        }
    }
    .imagen-beneficios {
        margin-top: 1rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3),0 0 3rem rgba(0, 0, 0, 0.1) inset;
        -moz-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
        box-shadow:0 0 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
        border-radius: 0.8rem;
        width: 90%;
    }
}


#carousel-comercios{
    //height: 375px;
    z-index: 1020;
    h4 {
        font-size: 1.5rem;
        margin: 0;
        text-align: center;
        color: $primary-blue;
    }
    .carousel-indicators {
        opacity: 0;
    }
    .carousel-inner {
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        text-shadow: 0 1px 2px rgba(0,0,0,0.6);
        padding: 1rem;
        img {
            padding: 0.5rem;
            -webkit-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3),0 0 3rem rgba(0, 0, 0, 0.1) inset;
            -moz-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 0 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
        }
        p.card-text {
            text-align: center;
        }
    }
}

.boton-dondecomprar {
    position: absolute;
    z-index: 1020;
    @include respond-to('small'){
        margin-top: -6rem;
    }
    @include respond-to('medium'){
        margin-top: -7rem;
    }
    @include respond-to('large'){
        margin-top: -8rem;
    }
    a.btn-dondecompra {
        border: 1px solid $primary-grey;
        @include gradient($primary-green, $primary-green-dark);
        @include respond-to('small'){
            width: 80%;
        }
        @include respond-to('medium'){
            width: 80%;
        }
        @include respond-to('large'){
            width: 70%;
        }
    }
}

.contactos-home {
    height: 10rem;
    -webkit-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3),0 0 3rem rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
    box-shadow:0 0 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
    display: flex;
    justify-content: center;
    align-items: center;
    //@include border-radius(1rem);
    flex-wrap: wrap;
    background-color: $primary-grey-light;
    opacity: 0.9;
    position: relative;
    z-index: 1020;
    margin-top: -10rem;
    img {
        width: 4rem;
        display:block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        @include box-shadow(0.5rem, 0.5rem, 1rem, $box-shadow);
        @include border-radius(2rem);
    }
    p {
        text-align: center;
        color: $primary-blue;
        &.contactos-home-titulo {
            font-family: $font-type-coolvetica;
            margin-bottom: 0;
            margin-top: .6rem;
        }
    }
    .row {
        flex-wrap: initial;
    }
}

.promos-diarias {
    z-index: 1020;
    position: relative;
    background-color: $primary-blue;
    h2 {
        color: $primary-grey-light-light;
        font-weight: bold;
    }
    h3 {
        color: $primary-blue;
        font-weight: bold;
        font-size: 1.4rem;
        text-shadow: 1px 0 $primary-blue;
        &.titulo-promo {
            margin-top: -2rem;
            font-size: 1.8rem;
            font-family: $font-type-coolvetica;
            letter-spacing: .1rem;
        }
    }
    .slider-hero {
        position: relative;
    }

    .owl-carousel {
        position: relative;
    }

    .owl-carousel .owl-item {
        opacity: 1;
    }

    .owl-carousel .owl-item.active {
        opacity: 1;
    }

    .owl-carousel .owl-nav {
        position: absolute;
        top: 50%;
        width: 100%;
    }

    .owl-carousel .owl-nav .owl-prev,
    .owl-carousel .owl-nav .owl-next {
        position: absolute;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-top: 0;
        color: white !important;
        -webkit-transition: 0.7s;
        -o-transition: 0.7s;
        transition: 0.7s;
        opacity: 1;
    }

    @media (prefers-reduced-motion: reduce) {
        .owl-carousel .owl-nav .owl-prev,
        .owl-carousel .owl-nav .owl-next {
            -webkit-transition: none;
            -o-transition: none;
            transition: none;
        }
    }

    .owl-carousel .owl-nav .owl-prev span:before,
    .owl-carousel .owl-nav .owl-next span:before {
        font-size: 30px;
    }

    .owl-carousel .owl-nav .owl-prev {
        left: 20px;
    }

    .owl-carousel .owl-nav .owl-next {
        right: 20px;
    }

    .owl-carousel .owl-dots {
        text-align: center;
        margin-top: 0;
        position: absolute;
        bottom: 20px;
        right: 0;
        left: 0;
    }

    .owl-carousel .owl-dots .owl-dot {
        width: 10px;
        height: 10px;
        margin: 5px;
        border-radius: 50%;
        //background: rgba(255, 255, 255, 0.5);
        position: relative;
    }

    .owl-carousel .owl-dots .owl-dot:hover, .owl-carousel .owl-dots .owl-dot:focus {
        outline: none !important;
    }

    .owl-carousel .owl-dots .owl-dot.active {
        background: #ff5959;
    }

    .owl-carousel.owl-drag .owl-item {
        -ms-touch-action: pan-y;
        touch-action: pan-y;
    }

    .work {
        width: 100%;
    }

    .work .img {
        width: 80%;
        height: 16rem;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        background-color: $primary-grey-light;
        @include box-shadow(0.5rem, 0.5rem, 1rem, $box-shadow);
        margin-bottom: 3rem;
        margin-right: auto;
        margin-left: auto;
    }

    .work .img .text{
        margin-top: 3rem;
    }

    .work .img:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: -1;
        background: #000;
        opacity: .3;
    }

    .thumbnail {
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        bottom: 0px;
        left: 50%;
        -webkit-transform: translateY(50%) translateX(-50%);
        -ms-transform: translateY(50%) translateX(-50%);
        transform: translateY(50%) translateX(-50%);
        z-index: 99;
        width: 100%;
    }

    .thumbnail li {
        display: inline-block;
    }

    .thumbnail li a {
        display: block;
        margin: 0px;
    }

    .thumbnail li a img {
        border-radius: .3rem;
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
        //-webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
        //box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
        padding: 2px;
        @include respond-to('small'){
            width: 35px;
        }
        @include respond-to('medium'){
            width: 45px;
        }
        @include respond-to('large'){
            width: 50px;
        }
    }

    .thumbnail li.active a img {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        //-webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
        //box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
        border: 2px solid #fff;
    }

    .img-logos {
        width: 6rem !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

}

