.modal-dialog {
    margin-top: 5rem;
    &.alertas {
        h2 {
            margin-top: 0;
            margin-bottom: 0;
            color: $danger;
            font-weight:map-get($font-weight,bold);
            text-shadow: 1px 0 $danger;
            line-height: 0.6;
        }
        .btn-alerta{
            @include border-radius(2rem);
            @include gradient($secondary-blue, $secondary-blue-ligth);
            @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
            width: 40%;
            //display: block;
            //margin-right: auto;
            //margin-left: auto;
            color: $primary-grey-light;
            font-family: $font-type-coolvetica;
            font-size: 1.2rem;
            padding-top: 0.6rem;
            &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
                background-color: $primary-orange;
                outline: none;
            }
        }
        img {
            width: 100%;
            display: block;
            margin: auto auto 1rem auto;
            padding-right: 2rem;
            &.destacada {
                width: 100%;
                display: block;
                margin: auto auto 1rem auto;
                padding-right: 2rem;
            }
        }
        ol {
            margin-bottom: 6rem;
        }
        li.alerta {
            margin-bottom: 2rem;
        }
    }
    &.info-vencimiento {
        .modal-body {
            &.color-verde {
                color: $primary-green;
            }

            &.color-azul {
                color: $primary-blue;
            }

            &.color-rojo {
                color: $brand-color;
            }

            &.color-gris {
                color: $primary-grey;
            }
        }
    }
}
