// LOADER
.loader{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    background-color: $loader;
    overflow: hidden;
}
.loader .spinner-border {
    color: $primary-blue;
}
.loader p{
    color: $light-base;
    margin-top: 1rem;
}