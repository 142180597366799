html {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: 15px;
}

.container-chat {
    position: fixed;
    //min-height: 3rem;
    width: 2.5rem;
    height: 3.5rem;
    text-align: center;
    word-wrap: break-word;
    //background-color: $primary-orange;
    display: flex;
    z-index: 1040;
    //border-radius: 2rem 0 0 2rem;
    top: 80%;
    right: 0;
    flex-wrap: wrap;
    img {
        border-radius: 2rem 0 0 2rem;
        display: flex;
        z-index: 1050;
        flex-wrap: wrap;
        position: fixed;
        top: 80%;
        right: 0;
        width: 2.5rem;
        height: 3.5rem;
        -webkit-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3),0 0 3rem rgba(0, 0, 0, 0.1) inset;
        -moz-box-shadow:0 1px 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
        box-shadow:0 0 2rem rgba(0, 0, 0, 0.3), 0 0 3rem rgba(0, 0, 0, 0.1) inset;
    }
}

a:focus {
    outline: none;
}