.pag-dinamica{
    font-family:$font-type-corbert;
    p {
        margin-top: 1rem;
    }
    pre{
        font-size: 1rem;
        font-family:$font-type-corbert;
        color: $text-color;
    }
    img{
        max-width: 100%;
        @include border-radius(0.7rem);
        overflow: hidden;
        margin-left: .5rem;
        margin-bottom: 1rem;
        &.destacada {
            margin: 1rem auto 1.5rem;
        }
    }
    ul {
        list-style: none;

        li::before {
            content: "\2022";
            color: $primary-blue;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
    address{
        font-style: italic;
    }
    a {
        color: $primary-blue;
        font-size: 1rem;
        &:hover {
            color: $primary-blue;
        }
        &.link-azul {
            color: $primary-blue;
        }
    }
    .tabla-consulta {
        p {
            margin-top: 0;
        }
        td {
            margin-bottom: 1rem;
        }
    }
    &.compra_online {
        h1 {
            margin-top: 2rem;
        }
        img {
            margin: 1rem;
            &.imagen-titulo {
                margin: 0;
            }
        }
        .color-blanco {
            color: $primary-grey-light;
        }
        .color-naranja {
            color: $primary-orange;
        }
        .color-azul {
            color: $primary-blue;
        }
        .color-verde {
            color: $primary-green;
        }
        .subtitulo-texto {
            font-size: 1.4rem;
        }
        .subtitulo-fondo {
            font-weight: bold;
            text-align: center;
            font-size: 1.2rem;
            @include box-shadow(0.5rem, 0.5rem, 1.2rem, $box-shadow-light);
            @include border-radius(0.7rem);
            &.color-blanco {
                color: $primary-blue;
                background-color: $primary-grey-light;
            }
            &.color-naranja {
                color: $primary-blue;
                background-color: $primary-orange;
            }
            &.color-azul {
                color: $primary-grey-light;
                background-color: $primary-blue;
            }
            &.color-verde {
                color: $primary-grey-light;
                background-color: $primary-green;
            }
        }
        table {
            margin-top: 2rem !important;
            margin-bottom: 2rem !important;
            ul.dtr-details {
                list-style: none;
                li::before {
                    content: "";
                }
            }
            span.dtr-data {
                li::before {
                    content: "\2022";
                    color: $primary-blue;
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em;
                }
            }
            .texto-largo {
                padding: 0 !important;
                p {
                    margin-top: .5rem;
                    margin-bottom: .5rem;
                }
            }
        }
    }
    .iframe-recarga {
        width: 100%;
        height: 40rem;
        #ModalRecarga {
            background-color: white;
        }
    }
    .iframe-pagoservicios {
        width: 100%;
        min-height: 200rem;
        position: relative;
    }
}
.back-novedades {
  background:$bg-back-novedades;
}
