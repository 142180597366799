// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
// FOOTER GENERAL ----------------------------------------------------------------------------------------------------------------------------------------------------
.footer-gral {
    z-index: 1020;
    .bloque-azul {
        background-color: $primary-blue;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        ul {
            margin-top: 0;
            li {
                &.nav-item {
                    margin-bottom: 0;

                    ul {
                        padding-bottom: .3rem;
                        padding-left: 2rem;
                    }
                }
                &.dropdown-item {
                    font-size: 1rem !important;
                    font-family: $font-type-coolvetica;
                    color: $primary-grey-light;
                }
            }
            .nav-link {
                padding-left: 0;
                font-size: 1.2rem;
                font-family: $font-type-coolvetica;
                color: $primary-grey-light;
            }
        }
        img.logo-passcard {
            width: 10rem;
        }
        .descripcion-footer, .titulo-footer {
            text-align: center;
        }
        span.seguinos {
            font-family: $font-type-coolvetica;
            color: $primary-grey-light;
            font-size: 1.2rem;
        }
        p.cabezal {
            font-family: $font-type-coolvetica;
            color: $primary-grey-light;
            font-size: 1.2rem;
            margin-left: 1rem;
            width: 100%;
        }
        p.menu-footer {
            margin-left: 1rem;
            margin-bottom: 0;
            font-size: 1rem;
            width: 100%;
            a {
                font-size: 1rem;
            }
        }
        .facebook-icon {
            width: 2rem;
            top:-1px;
            margin-right: 1rem;
            filter: invert(1);
            &:hover{
                opacity: 0.6;
                filter: invert(1);
            }
        }
        .linkedin-icon {
            width: 2rem;
            opacity: 0.9;
            margin-right: 1rem;
            filter: invert(1);
            &:hover{
                opacity: 0.6;
                filter: invert(1);
            }
        }
        .youtube-icon {
            width: 2rem;
            opacity: 0.9;
            margin-right: 1rem;
            filter: invert(1);
            flex-wrap: wrap;
            &:hover{
                opacity: 0.6;
                filter: invert(1);
            }
        }
        .instagram-icon {
            width: 2.2rem;
            opacity: 0.9;
            margin-right: 1rem;
            margin-left: 1rem;
            flex-wrap: wrap;
            &:hover{
                opacity: 0.6;
                filter: invert(1);
            }
        }
    }
    .bloque-oscuro {
        background-color: $primary-dark;
        height: 11rem;
        img {
            width: 8rem;
        }
    }
    .titulo-footer {
        font-family: $font-type-coolvetica;
        margin-bottom: 0;
    }
    p, a {
        color: $primary-grey-light;
    }
    img {
        &.mapa-icon, &.mail-icon{
            width: 2rem;
            display:block;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .letra-chica {
        font-size: .8rem;
        margin-top: 1rem;
        margin-bottom: 5rem;
        & a {
            font-size: .8rem;
        }
    }
}
// MENU FLECHAS COLLAPSE
ul.nav a.nav-link.collapsed svg.fa-angle-down {
    transform: rotate(-90deg);
    @include transition(all,.25s);
}
ul.nav a.nav-link svg.fa-angle-down {
    @include transition(all,.25s);
}

.footer-contacto{
    background-color: $primary-green;
    height: 4rem;
    position: fixed;
    bottom: -4rem;
    width: 100%;
    z-index: 1050;
    @include box-shadow(-1rem, 0rem, 1.5rem, $header-shadow);
    @include border-radius-partial(5rem, 5rem, 0, 0);
    @include transition(all, .3s, ease);
    img {
        width: 2.6rem;
        &.icono-telefono {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }
    &.visible{
        bottom: 0px;
        @include transition(all, .3s, ease);
    }
    .row {
        height: 100%;
    }
    &.no-fixed {
        position: relative;
        bottom:0px;
    }
    &.autogestion {
        background-color: $primary-green;
        //background-color: $primary-grey-light;
        @include border-radius-partial(3rem, 3rem, 0, 0);
        img {
            fill: $primary-blue;
            width: 1.4rem;
        }
        a {
            font-size: 0.8rem;
            color: $white;
            &.clientes {
                font-size: 1rem;
            }
        }
    }
}
