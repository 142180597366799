.drawer-hamburger {
    z-index: 1060 !important;
    padding-top: 0 !important;
    //right: 18rem !important;
    &:focus {
        outline: none;
    }
    span.drawer-hamburger-icon {
        background-color: $primary-green;
        &:before, &:after {
            background-color: $primary-green;
        }
    }
}
.drawer-open .drawer-hamburger-icon {
    background-color: transparent !important;
}
.drawer-close .drawer--right .drawer-nav {
    right: -18rem !important;
}
.drawer--right.drawer-open .drawer-hamburger {
    right: 18rem !important;
}
.drawer-open .drawer-nav {
    width: 18rem !important;
}
nav.drawer-nav {
    z-index: 1060 !important;
    //width: 18rem !important;
    background-color: $primary-grey-light-light;
    @include box-shadow(0rem, 0.2rem, 1.5rem, $header-shadow);
    a {
        overflow: hidden;
        white-space: initial;
        color: $primary-blue !important;
    }
    img {
        margin: 1rem;
    }
}
