input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}

.form-group{
    margin-bottom: 1.5rem;
}
.form-group.form-check{
    margin: 1.5rem 0;

    label{
        margin: 0;
        margin-left: .7rem;
    }
}

input.form-control, select.form-control{
    border-color: $border-color;
    @include border-radius($border-radius);
    height: $height-cta;
}

textarea.form-control{
    border-color: $border-color;
}

label.error, p.error {
    color: $danger;
    font-style: italic;
    margin-top: 0.8rem;
}
select.error, input.error, textarea.error {
    border: 1px solid #ff8792
}

span.error {
    border: 1px solid #ff8792;
    @include border-radius($border-radius);
}

.contacto {
    .form-check {
        label {
            margin-left: .2rem;
        }
    }
}

form {
    div.form-group div .form-check {
        margin-bottom: 0.8rem;
        margin-left: 0.8rem;
    }
    &#form_tarjeta {
        label.error {
            position: absolute;
            background-color: $primary-grey-dark;
            text-align: center;
            border-radius: 2rem;
            width: 150%;
            padding: .5rem;
        }
    }

    .custom-file-label::after {
        @include input-upload-file('../uploads/images/iconos/camera-solid.svg');
    }

    .select2-container .select2-selection--single{
        border-color: $border-color !important;
        height: $height-cta !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered{
        line-height: $height-cta !important;
    }

    .select2-selection__arrow{
        height: $height-cta !important;
    }

    .fecha-dinamica{
        background: none;
        // height: 90px;
        select{
            display: inline-block;
            width: auto;
            margin: 0 0.5rem;

            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    div.preview-attachment {
        width: 30%;
        height: 20%;
        margin-top: 5%;
        img {
            max-width: 100%;
        }
    }

    .form-group {
        .form-check {
            margin-left: 0;
            &.form-check-inline {
                margin-right: 1rem;
            }
        }
        .radio-dinamico {
            label {
                font-size: 1.0rem;
                color: $text-color;
                text-align: left;
                font-weight: 700;
            }
            .radio-vertical{
                margin: 0rem;
                padding: 0.2rem 0rem 0.4rem 0rem;
            }
            &.radio-imagen {
                input[type="radio"] {
                    position: absolute;
                    left: 1.5rem;
                    opacity: 0;
                }
                label {
                    border: 1px solid #fff;
                    //padding: 10px;
                    display: block;
                    position: relative;
                    margin-top: 1rem;
                    cursor: pointer;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    &.error {
                        color: $danger;
                        font-style: italic;
                    }
                }

                label::before {
                    background-color: white;
                    color: white;
                    content: " ";
                    display: block;
                    border-radius: 50%;
                    border: 1px solid $primary-blue;
                    position: absolute;
                    top: -5px;
                    left: -5px;
                    width: 25px;
                    height: 25px;
                    text-align: center;
                    line-height: 20px;
                    transition-duration: 0.4s;
                    transform: scale(0);
                }

                label img {
                    height: 100px;
                    width: 100px;
                    transition-duration: 0.2s;
                    transform-origin: 50% 50%;
                    border-radius: 50%;
                }

                //:checked + label {
                //    border-color: #ddd;
                //}

                :checked + label::before {
                    content: "✓";
                    background-color: $primary-blue;
                    transform: scale(1);
                    z-index: 1020;
                }

                :checked + label img {
                    transform: scale(0.9);
                    box-shadow: 0 0 5px #333;
                    z-index: -1;
                }

                img.cara {
                    @include respond-to('small'){
                        width: 2rem;
                        height: 2rem;
                    }
                    @include respond-to('medium'){
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                    @include respond-to('large'){
                        width: 3rem;
                        height: 3rem;
                    }
                }
            }
        }
    }
}

.select2-container.select2-container--default{
    width:100%!important;
    .select2-selection--single{
        height:$height-select2;
        .select2-selection__rendered{
            line-height:$height-select2;
        }
        .select2-selection__arrow{
            height:$height-select2;
        }
    }
    &.error {
        .select2-selection--single{
            border: none;
        }
    }
}

.img-fondo-ruleta {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100%;
    min-height: 100vh;
    position: relative;
    z-index: 1010;
    opacity: .8;
}
.form-fondo-ruleta {
    position: absolute;
    top: 16rem;
    z-index: 1040;
    padding-right: 1rem;
    padding-top: 1rem;
    background-color: hsla(hue($dark-base), 0%, 20%, .8);
    width: 100%;
    label {
        color: $white !important;
    }
}
.titulo-ruleta {
    position: absolute;
    top: -1rem;
    z-index: 1030;
    left: 0;
    right: 0;
    text-shadow: 1px 0 $white !important;
    color: $white !important;
    margin-top: 0;
    background-color: $button-green-2;
    padding-top: 1rem;
}

.ruleta-confondo {
    position: absolute;
    top: 6rem;
}
