.tarjeta-promocion {
    img {
        @include border-radius(0.7rem);
        overflow: hidden;
        max-width: 100%;
        margin-bottom: 2rem;
    }
}

.tarjeta-debitos {
    h2 {
        font-size: 1.2rem;
        font-weight: bold;
    }
    p.textos-tarjeta-debitos {
        font-size: 0.8rem;
        margin-bottom: 0;
    }
    ul li{
        font-size: 0.8rem;
    }
}

.tarjeta-seguros-asistencia{
    h2 {
        font-size: 1.2rem;
    }
    .img-destacada {
        overflow: hidden;
        @include border-radius(0.7rem);
        margin: 1rem auto 1.5rem;

        .destacada {
            @include border-radius(0.7rem);
            margin: 0;
            max-width: 100%;
        }
    }
    button {
        margin-top: 3rem;
    }
    .card {
        width: 80%;
        text-align: center;
        margin-bottom: 4rem;
        @include border-radius(0.7rem);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        img.card-img-top {
            @include border-radius(0.7rem);
        }
    }
}

.seccion-clientes {
    position: relative;
    margin-bottom: 3rem;
    h1 {
        color: $white;
        padding-top: 0;
        &.tutoriales {
            color: $primary-blue;
            padding-top: 4rem;
        }
    }
    h2 {
        margin: 0;
        font-size: 1.2rem;
        color: $white;
        &.tutoriales {
            color: $primary-blue;
        }
        &.subtitulos {
            font-size: 1.4rem;
            font-weight: bold;
        }
    }
    h3.titulo-cliente {
        color: $primary-grey-light;
        margin-top: 1rem;
        margin-bottom: 4rem;
        font-size: 1.2rem;
        &.tutoriales {
            color: $primary-blue;
        }
        &.izquierda {
            text-align: left;
        }
        &.derecha {
            text-align: right;
        }
        &.destacar-letra::first-letter {
            font-size: 2rem;
            font-weight: 900;
            text-shadow: 1px 0 $primary-blue;
            letter-spacing: 10px;
        }
    }
    a {
        img {
            @include border-radius(.5rem);
        }
        &.izquierda {
            margin-left: 0;
        }
        &.derecha {
            margin-right: 0;
        }
    }
    .card {
        margin-top: 3rem;
        @include border-radius(.5rem);
        background-color: $white;
        img {
            @include border-radius(.5rem);
        }
        .card-header {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            @include respond-to('small'){
                min-height: 3rem;
                font-size: .9rem;
            }
            @include respond-to('medium'){
                min-height: 4rem;
                font-size: 1rem;
            }
            @include respond-to('large'){
                min-height: 5rem;
                font-size: 1.2rem;
            }
        }
        .card-body {
            padding-bottom: 0rem;
            p {
                font-size: .9rem;
            }
            .card-title {
                margin-top: 0;
                font-weight: bold;
            }
        }
        .card-footer {
            span {
                text-align: center;
                display: block;
                color: $primary-blue;
            }
        }
    }
    iframe {
        width: 100%;
        min-height: 14rem;
        @include border-radius(0.7rem);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
    }
    ul {
        color: $primary-blue;
        li {
            margin-top: .3rem;
        }
    }
    .header-imagen {
        @include border-radius(0.7rem);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        display: block;
        &.izquierda {
            margin-left: 0;
            margin-right: auto;
        }
        &.derecha {
            margin-left: auto;
            margin-right: 0;
        }
    }
    .alert {
        margin-top: 3rem;
        margin-bottom: -2rem;
    }
    .img-izquierda {
        position: relative;
        float: left;
        margin-right: 1rem;
        width: 4rem;
    }
    .img-derecha {
        float: right;
        width: 25%;
        margin-left: 1rem;
    }
    .mensaje-alerta {
        color: #155724;
        background-color: #d4edda;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding: 0.75rem 1.25rem;
        border: 1px solid #c3e6cb;
        border-radius: 0.25rem;
    }
}

.landing-tarjeta-passcard {
    .header-solicitud {
        width: 100%;
        height: 50rem;
        margin-top: -4rem;
        background: rgba(0, 47, 112, 0.85);
        background: -webkit-linear-gradient(270deg, rgba(0, 47, 112, 0.85) 0%, #002f70 100%);
        background: linear-gradient(270deg, rgba(0, 47, 112, 0.85) 0%, #002f70 100%);
        position: absolute;
        z-index: 1000;

        clip-path: polygon(100% 0px, 100% 90%, 33.28% 95.09%, 21.28% 95.83%, 14.54% 96.26%, 11.07% 96.26%, 8.01% 95.78%, 5.29% 95.02%, 3.3% 93.89%, 2.03% 92.38%, 4px 90%, 0px 0px);
    }

    .fondo-form {
        background: rgba(0, 47, 112, 0.85);
        background: -webkit-linear-gradient(0deg, rgba(0, 47, 112, 0.85) 0%, #002f70 100%);
        background: linear-gradient(0deg, rgba(0, 47, 112, 0.85) 0%, #002f70 100%);
        border-radius: 25% 30% 25% 30% / 5% 5% 0% 0%;
    }

    .container {
        z-index: 1010;
        position: relative;
    }

    div.imagen-tarjetas img {
        width: 100%;
        margin-top: 2rem;
    }

    h1 {
        font-size: 1.6rem;
        padding: 0;
        text-align: center;
        margin-top: 0rem;
        color: #fff;
        text-shadow: 1px 0 #fff;
        letter-spacing: 1px;
    }

    span.titulo {
        display: flex;
        justify-content: center;
        font-size: 1.6rem;
        color: #fff;
    }

    span.subtitulo {
        display: flex;
        justify-content: center;
        font-size: 1.4rem;
        color: #fff;
    }

    .btn-home {
        padding: 0.5rem;
        margin-top: 2rem;
        background-color: #f2f2f2;
        color: #002f70;
        height: 46px;
        display: block;
        margin-right: auto;
        margin-left: auto;
        font-family: Coolvetica !important;
        font-size: 1.6rem;
        text-align: center;
        border-radius: 2rem;
        -webkit-border-radius: 2rem;
        -moz-border-radius: 2rem;
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#f2f2f2,endColorstr=#fafafa);
        background-image: -moz-linear-gradient(center left,#f2f2f2,#fafafa);
        background-image: -webkit-gradient(linear,50% 20%,0 0,from(#f2f2f2),to(#fafafa));
        box-shadow: .5rem .5rem 1rem rgba(0,0,0,.2);
        -webkit-box-shadow: .5rem .5rem 1rem rgba(0,0,0,.2);
        -moz-box-shadow: .5rem .5rem 1rem rgba(0,0,0,.2);
    }

    div.triangulo {
        font-size: 1.1rem;
        background-size: cover;
        min-height: 35vh;
        color: white;
        overflow: hidden;
        position: relative;
        border-radius: 2rem;
        z-index: 1010;
        padding: 1rem;
    }
    div.punta {
        width: 5rem;
        height: 5rem;
        transform: rotate(270deg);
        clip-path: ellipse(200px 55px at 210px);
        margin-left: auto;
        margin-right: auto;
        margin-top: -3rem;
        z-index: 1000;
        position: relative;
    }
    .caja-color-verde {
        background-color: #58C3B7;
    }

    .caja-color-azulclaro {
        background-color: #4479BD;
    }

    .caja-color-azulfuerte {
        background-color: #1E3264;
    }

    .caja-color-gris {
        background-color: #8c8c8c;
        //color: #4d4d4d !important;
        color: #fff !important;
    }

    .caja-color-negro {
        background-color: #222121;
        //color: #4d4d4d !important;
        color: #fff !important;
    }

    .caja-color-verdejoven {
        background-color: #05b6a1;
        //color: #4d4d4d !important;
        color: #fff !important;
    }

    form {
        margin-top: 4rem;
    }

    #formulario-solicitud {
        color: #fff !important;
    }

    #formulario-solicitud a {
        color: #fff !important;
    }

    .form-group input {
        color: #495057;
        background-color: #fff;
    }
    .imagen-tarjetaflechas {
        margin-top: -2rem;
    }
}
