.wrapper-autog {
    background-color: $primary-grey;
}
.logo-tienda {
    background-color: $primary-grey-light;
    border: 2px solid $primary-green;
    border-radius: 1rem;
    padding: 0.5rem;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
}
img.fondo-autogestion {
    max-width: 100%;
    z-index: 1010;
    //margin-top: -9rem;
    position: absolute;
    margin-top: -1rem;
    height: 100vh;
}
.dashboard-autogestion {
    //background: url('../uploads/images/iconos/autogestion/fondo-dashboard.jpg');
    position: relative;
    max-width: 100%;
    max-height: 100%;
    margin-top: 4rem;
    z-index: 1020;
    h1 {
        position: relative;
    }
    .card {
        .card-header {
            line-height: 0.6;
            margin-top: 0;
            &.card-gestion {
                line-height: 1.2;
            }
            @include respond-to('small'){
                font-size: 1.2rem;
            }
            @include respond-to('medium'){
                font-size: 1.4rem;
            }
            @include respond-to('large'){
                font-size: 1.6rem;
            }
        }
        .card-body {
            padding-top: 0.4rem;
            padding-bottom: 0.6rem;
            &.dashboard {
                padding-top: 0rem;
                padding-bottom: 0rem;
            }
            h5.card-title {
                font-size: 1.6rem;
                margin-bottom: 0.4rem;
            }
            p {
              margin-bottom: .5rem;
            }
            p.card-text {
                text-align: center;
                @include respond-to('small'){
                    font-size: .8rem;
                }
                @include respond-to('medium'){
                    font-size: .9rem;
                }
                @include respond-to('large'){
                    font-size: 1rem;
                }
                &.color-verde {
                    color: $primary-green;
                }
                &.color-azul {
                    color: $primary-blue;
                }
                &.color-rojo {
                    color: $brand-color;
                }
                &.color-gris {
                    color: $primary-grey;
                }
                &.dasboard {
                    font-size: 1rem;
                }
                &.info-pagocorazon {
                    animation: latidos .8s infinite;
                    position: absolute;
                    right: 1rem;
                    top: 0;
                    a {
                        font-size: 1.6rem;
                    }
                }
                &.info-pago {
                    position: absolute;
                    right: 1rem;
                    top: 0;
                    a {
                        font-size: 1.6rem;
                    }
                }

            }
        }
        &.card-formulario {
            margin-bottom: 8rem !important;
            button {
                margin-bottom: 1rem;
            }
        }
        &.card-acciones {
            &.una-columna {
                width: 12rem;
                height: 12rem;
                .card-body {
                    @include border-radius-partial(2rem, 2rem, 2rem, 2rem);
                    background: $light-base;
                    display: flex;
                    align-items: center;

                    .card-title {
                        font-size: 1.4rem;
                        text-align: center;
                        font-weight: bold;
                        color: $primary-blue;
                        margin: 0 auto;
                    }

                    .card-text {
                        color: $primary-blue;
                    }

                    img {
                        width: 3rem;
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                    }
                }
            }
            &.dos-columnas {
                @include respond-to('small'){
                    width: 8rem;
                    height: 8rem;
                }
                @include respond-to('medium'){
                    width: 9rem;
                    height: 9rem;
                }
                @include respond-to('large'){
                    width: 10rem;
                    height: 10rem;
                }
                .card-body {
                    @include border-radius-partial(2rem, 2rem, 2rem, 2rem);
                    background: $light-base;
                    display: flex;
                    align-items: center;

                    .card-title {
                        text-align: center;
                        font-weight: bold;
                        color: $primary-blue;
                        margin: 0 auto;
                        @include respond-to('small'){
                            font-size: .8rem;
                        }
                        @include respond-to('medium'){
                            font-size: 1rem;
                        }
                        @include respond-to('large'){
                            font-size: 1.1rem;
                        }
                    }

                    .card-text {
                        color: $primary-blue;
                    }

                    img {
                        margin: 1rem auto;
                        display: block;
                        @include respond-to('small'){
                            width: 2.5rem;
                        }
                        @include respond-to('medium'){
                            width: 3rem;
                        }
                        @include respond-to('large'){
                            width: 3rem;
                        }
                    }
                }
            }
        }
    }
    .contenedor-card {
        margin: 0 auto;
        width: 14rem;
        a {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }
        //height: 14rem;
        //margin-top: 2rem;
    }
    .cuadro-debito {
        @include border-radius-partial(2rem, 2rem, 2rem, 2rem);
        border: 1px $primary-grey-dark solid;
        margin-bottom: 1rem;
        height: 80px;
        h3 {
            font-size: 1.2rem;
            text-align: center;
            font-weight: bold;
            color: $primary-blue;
            //margin: 0 auto;
        }
    }
    img.img-cadadia {
        width: 100%;
        margin-bottom: .5rem;
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        @include border-radius(.5rem);
    }
}

@keyframes latidos {
    from { transform: none; }
    50% { transform: scale(1.2); }
    to { transform: none; }
}

.servicios-recomendados {
    .card {
        h3 {
            margin-top: 1rem;
        }
        .card-body {
            padding-left: 0;
        }
    }
}
img.logo-debito {
    //width: 100px;
    //height: 80px;
    margin-top: 0;
    margin-left: .6rem;
    padding: 0.1rem 0.3rem 0.3rem 0.3rem;
}

.div-terminos{
    height: 20rem;
    overflow-y: scroll;
    width:100%;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.iframe-pagoservicios {
    width: 100%;
    height: 100%;
    min-height: 200rem;
    position: absolute;
}

.listado-alertas-cliente {
    h2 {
        margin-top: 0;
        margin-bottom: 0;
        color: $danger;
        font-weight:map-get($font-weight,bold);
        text-shadow: 1px 0 $danger;
        line-height: 0.6;
    }
    .btn-alerta{
        @include border-radius(2rem);
        @include gradient($secondary-blue, $secondary-blue-ligth);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        width: 40%;
        color: $primary-grey-light;
        font-family: $font-type-coolvetica;
        font-size: 1.2rem;
        padding-top: 0.6rem;
        &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
            background-color: $primary-orange;
            outline: none;
        }
    }
    img {
        width: 100%;
        display: block;
        margin: auto auto 1rem auto;
        padding-right: 2rem;
        &.destacada {
            width: 100%;
            display: block;
            margin: auto auto 1rem auto;
            padding-right: 2rem;
        }
    }
    ol {
        margin-bottom: 6rem;
    }
    li.alerta {
        margin-bottom: 2rem;
    }
}
