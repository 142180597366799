.div-promocion{
    margin-top: 1.5rem;
    &:first-child{
        margin-top: 0;
    }

    a {
        display: inline-block;

        &:hover, &:focus, &:visited{
            text-decoration: none;
        }
    }
    .promocion-fecha{
        color: $primary-blue;
    }
    .promocion-bajada{
        color: $text-color;
    }
    img {
        @include border-radius(0.7rem);
        overflow: hidden;
        max-width: 100%;
    }
}
.img-promo-destacada {
    overflow: hidden;
    @include border-radius(0.7rem);
    @include respond-to('medium'){
        height: 320px;
    }
    @include respond-to('large'){
        height: 375px;
    }
    margin-left: .5rem;
    .destacada {
        margin: 1rem auto 1.5rem;
        @include border-radius(0.7rem);
        max-width: 100%;
    }
}

ul.pagination {
    li.active {
        span {
            background-color: $primary-blue !important;
            border-color: $primary-blue !important;
        }
    }
}

div.beneficios {
    .card {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        @include border-radius(0.7rem);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
        img {
            @include border-radius-partial(0.7rem, 0.7rem,0,0);
            //height: 14rem;
            width: 100%;
        }
        .card-body {
            h4 {
                margin-top: 0;
                text-align: center;
            }
        }
    }
    div.carousel-inner {
        div.card {
            border: none;
        }
    }
    margin-bottom: 5rem;
    #carousel-cierres {
        ol.carousel-indicators {
            margin: 0;
            bottom: -3rem;
        }

        .carousel-indicators li {
            background-color: $primary-blue;
            width: 10px;
            height: 10px;
            border: .5rem solid transparent;
            border-radius: 25px;
            //margin: .8rem .1rem;
        }
    }
    #carousel-comercios{
        .carousel-inner{
            position: relative;
            margin-top: 3rem;
        }
    }
    img.img-cadadia {
        max-width: 100%;
    }
    hr {
        margin-top: 3rem;
    }
    a {
        //text-decoration: underline;
        color: $primary-blue;
    }
    .solicita-tarjeta {
        img {
            width: 100%;
            @include border-radius(0.7rem);
        }
        a {
            padding-top: 0.8rem;
            text-decoration: none;
        }
        hr {
            margin-top: 6rem;
        }
    }
    form#form_tarjeta {
        .form-group {
            width: 100%;
            flex: 100%;
            max-width: 100%;
        }
        #tarjeta_save {
            @include border-radius(2rem);
            @include gradient($button-color-1, $button-color-2 );
            @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
            width: 100%;
            display: block;
            margin-right: auto;
            margin-left: auto;
            color: $primary-grey-light;
            font-family: $font-type-coolvetica;
            font-size: 1.2rem;
            padding-top: 0.4rem;
            &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
                background-color: $primary-orange;
                outline: none;
            }
        }
    }
    .tipo-tarjeta {
        div.card {
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                //margin-top: 1rem;
            }
            a.btn {
                position: absolute;
                margin-left: 20%;
                bottom: -2rem;
            }
            h4 {
                font-weight: bold;
            }
        }
    }
}

.promociones {
    margin-bottom: 5rem;
    .card {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        @include border-radius(0rem);
        @include box-shadow(0rem, 0rem, 0rem, $header-shadow);
        background-color: #fafafa;
        img {
            @include border-radius(0rem);
            height: 14rem;
            width: 100%;
            margin-top: -1.2rem;
            &.logo-dia {
                margin-right: 1rem;
                width: 2rem;
                height: 2rem;
                //@include respond-to('small'){
                //    width: 3rem;
                //    height: 3rem;
                //}
                //@include respond-to('medium'){
                //    width: 4rem;
                //    height: 4rem;
                //}
                //@include respond-to('large'){
                //    width: 4rem;
                //    height: 4rem;
                //}
            }
        }
        .card-body {
            h4 {
                margin-top: 0;
            }
        }
    }
    #carousel-cierres {
        ol.carousel-indicators {
            margin: 0;
            bottom: -2rem;
        }
        .carousel-indicators li {
            background-color: $primary-blue;
            width: 10px;
            height: 10px;
            border: .5rem solid transparent;
            border-radius: 25px;
            //margin: .8rem .1rem;
        }
    }
    div.carousel-inner {
        div.card {
            border: none;
        }
    }
    img.img-cadadia {
        max-width: 100%;
    }
    hr {
        margin-top: 3rem;
    }
    a {
        //text-decoration: underline;
        color: $primary-blue;
    }
    .solicita-tarjeta {
        img {
            width: 100%;
            @include border-radius(0.7rem);
        }
        a {
            padding-top: 0.8rem;
            text-decoration: none;
        }
        hr {
            margin-top: 6rem;
        }
    }
    .otras-promos {
        h2 {
            margin-top: 6rem;
            text-align: center;
            margin-bottom: 2rem;
        }
        .card {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            @include border-radius(0.7rem);
            @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
            img {
                @include border-radius-partial(0.7rem, 0.7rem,0,0);
                height: 22rem;
                width: 100%;
            }
            .card-body {
                h4 {
                    margin-top: 0;
                }
            }
        }
    }
    form#form_tarjeta {
        .form-group {
            width: 100%;
            flex: 100%;
            max-width: 100%;
        }
        #tarjeta_save {
            @include border-radius(2rem);
            @include gradient($button-color-1, $button-color-2 );
            @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
            width: 100%;
            display: block;
            margin-right: auto;
            margin-left: auto;
            color: $primary-grey-light;
            font-family: $font-type-coolvetica;
            font-size: 1.2rem;
            padding-top: 0.4rem;
            &:focus, &:hover, &:visited, &:active, &:not(:disabled):not(.disabled):active {
                background-color: $primary-orange;
                outline: none;
            }
        }
    }
    #carousel-otraspromos {
        margin-top: 2rem;
        margin-bottom: 4rem;

        @include centrar-elemento();
        .carousel-inner {
            align-content: center;
            display: flex;
            //.carousel-item {
            //    width: 80%;
            //    display: block;
            //    margin-left: auto;
            //    margin-right: auto;
            //}
            img {
                @include border-radius(1rem);
                @include box-shadow(0rem, 0.5rem, 1.5rem, $box-shadow);
                width: 85%;
                @include centrar-elemento();
                margin-bottom: 2rem;
            }
        }
        .carousel-control-prev{
            opacity: .75;
            font-size: 2rem;
            svg {
                margin-right: 5rem;
                color: $primary-blue;
            }
        }
        .carousel-control-next{
            opacity: .75;
            font-size: 2rem;
            svg {
                margin-left: 5rem;
                color: $primary-blue;
            }
        }
        ol.carousel-indicators {
            margin: 0;
        }
        .carousel-indicators {
            bottom: -4rem;
            li {
                width: 15px;
                height: 15px;
                border: .5rem solid transparent;
                border-radius: 25px;
                margin: .8rem .1rem;
                background-color: $primary-blue;
                opacity: .5;
                &.active {
                    opacity: .75;
                }
            }
        }
    }
}
