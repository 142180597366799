// compileOnSave: true
@charset 'UTF-8';

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";
// 1. Configuration and helpers
@import 'abstracts/all';
// 2. Base stuff
@import 'base/all';
// 4. Layout-related sections
@import 'layout/all';
// 5. Componentss
@import 'components/all';
// 6. Page-specific styles
@import 'pages/all';
//// 7. Themes
//@import 'themes/default';
   