
// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}
// BREAKPOINTS and media querys mobile project
$initial:0px;
$iphone-5:320px;
$galaxy:360px;
$iphone-plus:412px;

// BREAKPOINTS and media querys desktop
$tablet:768px;
$old_desktop:991px;
$desktop:1023px;
$pre_wide:1130px;
$wide:1280px;
$wide-slider:1399px;

$breakpoints: (
  'initial':(min-width:$initial),
  'small': (min-width: $iphone-5),
  '<small':(max-width:$iphone-5),
  'medium': (min-width: $galaxy),
  '<medium': (max-width: $galaxy),
  'large': (min-width: $iphone-plus),
  '<large': (max-width: $iphone-plus),
  'tablet': (min-width: $tablet),
  '<tablet':(max-width:$tablet),
  'old_desktop': (min-width: $old_desktop),
  '<old_desktop': (max-width: $old_desktop),
  'desktop': (min-width: $desktop),
  '<desktop': (max-width: $desktop),
  'pre_wide': (min-width: $pre_wide),
  '<pre_wide': (max-width: $pre_wide),
  'wide': (min-width: $wide),
  '<wide': (max-width: $wide),
  'wide_slider': (min-width: $wide-slider),
  '<wide_slider': (max-width: $wide-slider),
);
/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}


//Cross browser CSS3 mixins

@mixin box-shadow($left, $top, $radius, $color) {
	box-shadow: $left $top $radius $color;
	-webkit-box-shadow: $left $top $radius $color;
	-moz-box-shadow: $left $top $radius $color;
}
@mixin box-shadow-2($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin transition($property, $duration, $easing: linear) {
	transition: $property $duration $easing;
	-webkit-transition: $property $duration $easing;
	-moz-transition: $property $duration $easing;
}

@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}

@mixin border-radius-partial($topleft, $topright, $bottomright, $bottomleft) {
	border-top-left-radius: $topleft;
	border-top-right-radius: $topright;
	border-bottom-right-radius: $bottomright;
	border-bottom-left-radius: $bottomleft;
	-webkit-border-top-left-radius: $topleft;
	-webkit-border-top-right-radius: $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius: $bottomleft;
	-moz-border-radius-topleft: $topleft;
	-moz-border-radius-topright: $topright;
	-moz-border-radius-bottomright: $bottomright;
	-moz-border-radius-bottomleft: $bottomleft;
}

@mixin gradient($color1, $color2) {
	background-color: $color1;
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$color1}, endColorstr=#{$color2});
	background-image: -moz-linear-gradient(center left, $color1, $color2);
	background-image: -webkit-gradient(linear, 50% 20%, 0% 0%, from($color1), to($color2));
}
@mixin gradient-direction($direction, $color1, $color2) {
  background: -webkit-linear-gradient($direction, $color1, $color2);
  background: -moz-linear-gradient($direction, $color1, $color2);
  background: -o-linear-gradient($direction, $color1, $color2);
  background: linear-gradient($direction, $color1, $color2);
}

/* FORMULARIO DINÁMICO */
@mixin input-upload-file($dir) {
  content: "";
  width: 3rem;
  height: 100%;
  background-image: url($dir);
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position: center center;
}

@mixin centrar-elemento() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
