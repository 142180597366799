.div-collapse{
    .card {
        border: none;
        .card-header{
            background: none;
            border-bottom: none;
            border-top: 1px solid $border-collapse;
            padding: 1.25rem;

            h2 {
                margin-top: 0;

                span{
                    font-size: 1.2em;
                }
            }
            button.btn-link{
                color: $primary-green-dark;
                width: 100%;
                text-align: left;
                line-height: 2rem;
                height: auto;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:hover {
                    text-decoration: none;
                }
                &.collapsed svg.fa-angle-down, &.collapsed i.fa-angle-down {
                    transform: rotate(-90deg);
                    @include transition(all,.25s);
                }
                & svg.fa-angle-down, & i.fa-angle-down {
                    @include transition(all,.25s);
                }
            }
        }
        .card-body{
            padding-top: .5rem;

            .sucursales-mapa{
                margin-bottom: 1.5rem;
            }
            svg{
                color: $primary-blue;
                margin-right: .6rem;
                height: 100%;
                width:1.2rem;
                opacity:0.8;
            }
            ul{
                padding-left: 1.5rem;
            }
        }
    }
    .card:last-child {
        border-bottom: 1px solid $border-collapse;
    }
    button{
        text-transform: none;
        i.fa-map-marker-alt, svg.fa-map-marker-alt{
            margin-right: 1rem;
        }
        i.fa-angle-down, svg.fa-angle-down {
            float: right;
            line-height: 2rem;
            font-size: 1.4rem;
        }
    }
}
