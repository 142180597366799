/**
 * Basic typography style for copy text
 */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
  font-display: swap;
}

body {
  color: $text-color;
  font-family: $font-type-corbert;
  font-size: 1em;
  background-color: #fafafa;
}
h1 {
    //text-transform: uppercase;
    font-weight:map-get($font-weight,bold);
    //color: $secondary-color;
    font-size: 1.6em;
    padding: 2rem 0 .8rem;
    text-align: center;
    margin-top: 5rem;
    color: $primary-blue;
    font-family: $font-type-corbert;
    text-shadow: 1px 0 white;
    letter-spacing: 1px;
    &.web {
        margin-top: 1rem;
        font-size: 2rem;
        font-weight:map-get($font-weight,bold);
        text-shadow: 1px 0 $primary-blue;
    }
}
h2{
  font-size: 1.8rem;
  //color: $secondary-color;
  margin: 2rem 0 .8rem
}
h3{
  font-size: 1.2rem;
  //color: $secondary-color;
  margin: 2rem 0 .8rem
}
h4{
  font-size: 1.2rem;
  margin: 2rem 0 .8rem
}
h5{
  font-size: 1.1rem;
  margin: 2rem 0 .8rem
}
h6{
  font-size: 1rem;
  margin: 2rem 0 .8rem
}

a{
  color: $text-color;
  font-size: 1rem;
  &:hover {
      color: $primary-blue;
  }
  &.link-azul {
    color: $primary-blue;
  }
}

ul.nostyle{
  font-size: 1.2rem;
   i, svg{
      font-size: 3rem;
    }
}

.aclaracion{
  font-size: .8rem;
  color: $text-color;
}

caption{
    padding-top: 0.25rem;
    //color: $secondary-color;
}

blockquote {
  border-left: 0.5rem solid $primary-green;
  margin: 1.5rem 1rem;
  padding: 0.3rem 0.4rem;
  p {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
}

.mensaje-informacion {
  font-size: 1.4rem;
  text-align: justify;
}
