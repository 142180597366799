.login-wrapper {
    .alert-danger {
        margin-bottom: 1rem;
    }
    .form-widget-with-icon {
        align-items: baseline;
        display: flex;
        flex-direction: row;
        svg {
            color: $primary-grey-dark;
            font-size: 18px;
            margin-right: 10px;
        }
    }
}