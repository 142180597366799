.calendario-cierres {
    margin-bottom: 8rem;
    #carousel-cierres {
        ol.carousel-indicators {
          margin: 0;
          bottom: -3rem;
        }

        .carousel-indicators li {
          background-color: $primary-blue;
          width: 10px;
          height: 10px;
          border: .5rem solid transparent;
          border-radius: 25px;
          //margin: .8rem .1rem;
        }
    }
    p {
      font-size: 1.2rem;
    }
  table {
    width: 100%;
    font-size: 1.2rem;
  }
}
