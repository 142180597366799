.slider-home{
    z-index: 1020;
    overflow: hidden;
    @include border-radius-partial(0, 0, 0.8rem, 0.8rem);
    @include box-shadow(0rem, 0.5rem, 1.5rem, $box-shadow);
    .carousel-control-prev, .carousel-control-next{
        opacity: .75;
        height: 80%;
    }
    ol.carousel-indicators {
        margin: 0;
        bottom: 3rem;
    }
    .carousel-indicators li {
        width: 15px;
        height: 15px;
        border: .5rem solid transparent;
        border-radius: 25px;
        //margin: .8rem .1rem;
    }
    .carousel-caption {
        &.arriba {
            top: 1.5rem;
            bottom: auto;
        }
        &.abajo {
            bottom: 3rem;
        }
        h2 {
            font-size: 2rem;
            font-weight: bold;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
            &.color-blanco {
                color: $primary-grey-light;
            }
            &.color-naranja {
                color: $primary-orange;
            }
            &.color-azul {
                color: $primary-blue;
            }
            &.color-verde {
                color: $primary-green;
            }
        }
    }
}