
#preguntas-frecuentes{
    margin-top: 2rem;
    padding-bottom: 3rem;
    ul {
        list-style: none;
        li::before {
            content: "\2022";
            color: $primary-blue;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
    .card-body {
        h2:first-child, h3:first-child, ul:first-child {
            margin-top: 0.5rem;
        }
    }
}
