.progress {
    height: .4rem;
    @include box-shadow(0.1rem, 0.2rem, 0.1rem, $header-shadow);
}

.autogestion {
    .progress-autogestion {
        position: relative;
        margin: .5rem 1rem 0 1rem;
        float:left;
        text-align: center;
        span, p {
            &.color-verde {
                color: $primary-green;
            }
            &.color-azul {
                color: $primary-blue;
            }
            &.color-rojo {
                color: $brand-color;
            }
            &.color-gris {
                color: $primary-grey;
            }
        }
        .monto {
            @include respond-to('small'){
                font-size: 1.2rem;
            }
            @include respond-to('medium'){
                font-size: 1.25rem;
            }
            @include respond-to('large'){
                font-size: 1.4rem;
            }
        }
        .textomonto {
            @include respond-to('small'){
                font-size: .8rem;
            }
            @include respond-to('medium'){
                font-size: .8rem;
            }
            @include respond-to('large'){
                font-size: .9rem;
            }
        }
        &.pago {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .barOverflow{ /* Wraps the rotating .bar */
        position: relative;
        overflow: hidden; /* Comment this line to understand the trick */
        width: 90px;
        height: 45px; /* Half circle (overflow) */
    }
    .bar {
        position: absolute;
        top: 0; left: 0;
        width: 90px; height: 90px; /* full circle! */
        border-radius: 50%;
        box-sizing: border-box;
        border: 10px solid #eee;     /* half gray, */
        &.color-verde {
            border-bottom-color: $primary-green;
            border-right-color: $primary-green;
        }
        &.color-azul {
            border-bottom-color: $primary-blue;
            border-right-color: $primary-blue;
        }
        &.color-rojo {
            border-bottom-color: $brand-color;
            border-right-color: $brand-color;
        }
        &.color-gris {
            border-bottom-color: $primary-grey;
            border-right-color: $primary-grey;
        }
    }
}
