
// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
header {
    background-color: $primary-grey-light;
    height: 50px;
    text-align: left;
    width: 100%;
    top: 0;
    z-index: 1050;
    right: 0;
    left: 0;
    display: block;
    color: $primary-blue;
    @include transition (top, 0.5s, ease-in-out);
    &.header-principal {
        position: fixed;
        padding: 1rem;
        @include box-shadow(0rem, 0.5rem, 1.5rem, $header-shadow);
        img {
            &#header-imagen {
                margin-top: .5rem;
                width: 7rem;
            }

            &#header-imagen-facebook {
                width: 1.6rem;
                float: right;
                margin-right: 2rem;
            }

            &#header-imagen-instagram {
                width: 1.6rem;
                float: right;
                margin-right: 3.5rem;
            }
        }
    }
    &.autogestion {
        position: absolute;
        padding: 0.6rem;
        @include box-shadow(0rem, 0.5rem, 0.5rem, $header-shadow);
        div.row {
            margin-left: 0;
            margin-right: 0;
        }
        .circulo {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background: $primary-blue;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 1rem;
            b {
                color: $white;
                font-size: 1.4rem;
                font-weight: 900;
            }
        }
        .columna1 {
            padding-top: 0.2rem;
            //padding-left: 1.6rem;
            .img-home {
                width: 2rem;
                margin-top: 0;
            }
        }
        .columna2 {
            margin-top: 0.2rem;
            font-weight: bold;
            font-size: 1.2rem;
            text-align: left;
        }
        .columna3 {
            .img-cerrar {
                width: 1.2rem;
                margin-top: 0.6rem;
            }
        }
        a#campana-alerta {
            display:none;
            color: $primary-blue;
            span {
                position: absolute;
                top: 0.5rem;
            }
        }
    }
    a.boton-login {
        color: $primary-grey-light-light;
        font-family:$font-type-coolvetica;
        text-shadow: none;
        font-weight: normal;
        padding: .4rem .6rem;
        position: absolute;
        right: 5rem;
        z-index: 1010;
        top: .4rem;
        @include border-radius(1rem);
        @include gradient($button-green-1, $button-green-2);
        @include box-shadow(0.5rem, 0.5rem, 1rem, $header-shadow);
    }
}
