
.dataTables_wrapper {
    span.texto-largo {
        width: 200px;
        padding: 2px 5px;
        overflow: hidden;
        white-space: initial;
    }
    div.row {
        div.dataTables_wrapper div.dataTables_length select {
            appearance:none;
        }
        .dataTables_info {
            width: 100%;
            padding: 2px 5px;
            white-space: nowrap;
            overflow: hidden;
            white-space: initial;
        }

        .dataTables_paginate {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .page-item.active .page-link {
                background-color: $primary-blue;
                border-color: $primary-blue;
                color: $primary-grey-light;
            }
            .page-link {
                color: $primary-blue;
            }
        }
    }
    table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child::before {
        background-color: $primary-green-dark;
    }
    table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child::before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child::before {
        background-color: $primary-orange;
    }
}